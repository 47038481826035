<!-- eslint-disable vue/no-dupe-keys -->
<script setup lang="ts">
// eslint-disable-next-line import/named
import { ref, onMounted, watch, Ref, computed } from 'vue'
import { InputType, InputVariant, IconNames, IconColors } from '@temperworks/types'
import { TemperIcon } from '@temperworks/icons'

interface Props {
  id?: string
  variant: InputVariant
  type: InputType
  placeholder: string
  input?: string
  label?: string
  required?: boolean
  deleteOption?: boolean
  focus?: boolean
  disabled?: boolean
  error?: boolean
  stored?: boolean
  maxlength?: number
  disableBlurHandler?: boolean
  selectOnFocus?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  id: '',
  deleteOption: true,
  input: undefined,
  label: '',
  maxlength: 1000,
  disableBlurHandler: false
})
const emit = defineEmits(['input', 'active', 'clear', 'blur'])
const inputValue: Ref<string> = ref<string>('')
const input: Ref<HTMLInputElement | null> = ref<any>(null)
const passwordOpen: Ref<boolean> = ref<boolean>(false)
const active: Ref<boolean> = ref<boolean>(false)
const textLength = computed(() => inputValue?.value?.length ?? 0)

onMounted(() => {
  if (props.input !== undefined) {
    inputValue.value = props.input || ''
  }

  if (props.focus) {
    input.value.focus()
  }
})

watch(() => props.input, (newVal) => {
  inputValue.value = newVal
})

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement
  inputValue.value = target.value

  emit('input', target.value)
  active.value = true

  if (props.stored) {
    emit('active', active.value)
  }
}

function handleBlur(event: Event) {
  const target = event.target as HTMLInputElement
  emit('blur', target.value)
  !props.disableBlurHandler && handleInput(event)
}

function clear() {
  inputValue.value = ''

  setActive()

  emit('clear')
  emit('input', '')
}

function showPassword() {
  passwordOpen.value = !passwordOpen.value
}

function setActive() {
  input.value.focus()
  active.value = true

  if (props.selectOnFocus) {
    input.value.select()
  }

  if (props.stored) {
    emit('active', active.value)
  }
}

function setNotActive() {
  active.value = false
}
</script>

<template>
  <div
    class="input-field"
    :class="props.variant"
  >
    <input
      :id="props.id"
      ref="input"
      :type="passwordOpen ? 'text' : InputType[props.type]"
      :placeholder="props.variant.includes('label') ? '' : props.placeholder"
      :value="inputValue"
      :maxlength="props.maxlength"
      :class="[
        props.variant,
        { 'error': props.error },
        { 'active': textLength > 0 },
        { 'disabled': props.disabled },
        { 'stored' : props.stored }
      ]"
      class="input-field-inner"
      :aria-required="props.required"
      @focus="setActive"
      @focusout="setNotActive"
      @input="handleInput"
      @blur="handleBlur"
    >
    <label
      v-if="props.variant.toLowerCase().includes('label')"
      class="inner-label"
      :class="{ 'active': textLength > 0 }"
    >
      {{ props.label }}
    </label>
    <span
      v-if="props.type === 'search'"
      class="search"
    >
      <TemperIcon
        :name="IconNames.search"
        :size="props.variant.includes('small') ? 'small' : 'medium'"
        :color="IconColors.grey400"
        position="left"
      />
    </span>
    <span
      v-if="
        textLength > 0 &&
          (props.type !== 'password' && props.deleteOption)
          && !props.disabled
      "
      id="remove"
      class="remove"
      :class="
        [{'mt-0': (props.variant.toLowerCase().includes('label') && props.type === 'search')}]
      "
      @click="clear()"
    >
      <TemperIcon
        v-if="!props.variant.includes('withIconSmall')"
        :name="IconNames.bubbleClearFilled"
        :color="IconColors.grey800"
        size="medium"
      />
    </span>
    <span
      v-if="props.stored && !active"
      class="edit"
    >
      <TemperIcon
        :name="IconNames.edit"
        :color="IconColors.grey800"
        size="medium"
      />
    </span>
    <span
      v-if="textLength > 0 && props.type === 'password'"
      class="remove"
      @click="showPassword()"
    >
      <TemperIcon
        :name="passwordOpen ? IconNames.eyeOff : IconNames.eyeOn"
        position="right"
        :color="IconColors.grey800"
      />
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use './InputField.scss';
</style>
